/* eslint-disable no-undef */
"use strict";
var formValidation = require("base/components/formValidation");
$("form.communication-preferences").submit(function (e) {
    var $form = $(this);
    e.preventDefault();
    var url = $form.attr("action");
    $form.spinner().start();
    $.ajax({
        url: url,
        type: "post",
        dataType: "json",
        data: $form.serialize(),
        success: function (data) {
            $form.spinner().stop();
            if (!data.success) {
                formValidation($form, data);
            } else {
                location.href = data.redirectUrl;
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            }
            $form.spinner().stop();
        }
    });
    return false;
});

// Get the "want-to-subscribe" and "dont-want-subscribe" checkbox elements
const wantToSubscribeCheckbox = document.getElementById("want-to-subscribe");
const dontWantToSubscribeCheckbox = document.getElementById("dont-want-subscribe");
const smsCheckBox = document.getElementById("sms");

// Get all the checkboxes that you want to disable
const checkboxes = document.querySelectorAll(".col-sm-12.col-md-6 .comm-pref input[type=\"checkbox\"]");

// Function to update checkboxes based on conditions
function updateCheckboxes(enable, check = false) {
    checkboxes.forEach(checkbox => {
        checkbox.checked = check;
        checkbox.disabled = !enable;
    });
}

if (wantToSubscribeCheckbox) {
    // Add an event listener to the "want-to-subscribe" checkbox
    wantToSubscribeCheckbox.addEventListener("change", () => {
        const isChecked = wantToSubscribeCheckbox.checked;
        if (dontWantToSubscribeCheckbox) {
            dontWantToSubscribeCheckbox.checked = false;
        }
        updateCheckboxes(isChecked, isChecked);
    });

    // Add an event listener to the "dont-want-subscribe" checkbox
    if (dontWantToSubscribeCheckbox) {
        dontWantToSubscribeCheckbox.addEventListener("change", () => {
            if (dontWantToSubscribeCheckbox.checked) {
                updateCheckboxes(false);
                wantToSubscribeCheckbox.checked = false;
                if (smsCheckBox) {
                    smsCheckBox.checked = false;
                }
            } else {
                wantToSubscribeCheckbox.disabled = false;
                if (smsCheckBox) {
                    smsCheckBox.disabled = false;
                }
            }
        });
    }
}

if (smsCheckBox) {
    smsCheckBox.addEventListener("change", () => {
        if (smsCheckBox.checked) {
            dontWantToSubscribeCheckbox.checked = false;
        }
    });
}
