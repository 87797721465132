/* eslint-disable no-undef */
"use strict";

require("../slick/slick");
const breakpoints = require("../utils/breakpoints");

// eslint-disable-next-line no-undef
$(document).ready(function () {
    // eslint-disable-next-line no-undef
    $(".promo-bar-slider").slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    prevArrow: false,
                    autoplay: true, // Add this property
                    autoplaySpeed: 3000 // Set the autoplay speed in milliseconds
                }
            }
        ]
    });

    $(".product-type-slider").slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 9,
        slidesToScroll: 1,
        prevArrow: false,


        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                    prevArrow: false,
                    autoplay: false // Add this property
                }
            }
        ]
    });

    // Function to handle viewport size changes
    function handleViewportSizeChange() {
        if (!breakpoints.isLargeBreakpoint()) {
            $(".menu-wrapper").removeClass("mega-menu-desktop");
            $(".menu-wrapper").addClass("toggle-menu-mobile");
            $(".menu-wrapper .mobile-toggle-menu").show();
            $(".menu-wrapper").removeClass("d-none");
        } else {
            $(".menu-wrapper .mobile-toggle-menu").hide();
            $(".menu-wrapper").removeClass("toggle-menu-mobile");
            $(".menu-wrapper").addClass("mega-menu-desktop");
            $(".menu-wrapper").removeClass("d-none");
        }
    }

    // Call the function on page load
    handleViewportSizeChange();

    // Call the function on viewport size change
    $(window).resize(function () {
        handleViewportSizeChange();
    });

    const textArea = document.querySelector(".gift-registry-url");

    if (textArea) {
        // Remove extra spaces from the value
        textArea.value = textArea.value.replace(/\s+/g, " ").trim();
    }
});
