"use strict";


/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked to add a product to the wishlist
 */
function displayMessage(data, button, iconElement) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = "alert-success";
        if (iconElement.hasClass("icon-heart")) {
            iconElement.removeClass("icon-heart").addClass("icon-heart-dark");
        }
    } else {
        status = "alert-danger";
    }

    if ($(".add-to-wishlist-messages").length === 0) {
        $("body").append(
            "<div class=\"add-to-wishlist-messages \"></div>"
        );
    }
    $(".add-to-wishlist-messages")
        .append("<div class=\"add-to-wishlist-alert text-center " + status + "\">" + data.msg + "</div>");

    setTimeout(function () {
        $(".add-to-wishlist-messages").remove();
    }, 5000);
}

// Function to set the state of individual checkboxes
function setIndividualCheckboxes(isChecked) {
    var $individualCheckboxes = $(".individual-public-checkbox");
    $individualCheckboxes.each(function() {
        $(this).prop('checked', isChecked);
    });
}

module.exports = {
    addToWishlist: function () {
        $(".add-to-wish-list").on("click", function (e) {
            e.preventDefault();
            var url = $(this).attr("href");
            var button = $(this);
            var iconElement = button.find('i.icon-heart');
            var pid = $(this).closest(".product-detail").data("pid");
            var optionId = $(this).closest(".product-detail").find(".product-option").attr("data-option-id");
            var optionVal = $(this).closest(".product-detail").find(".options-select option:selected").attr("data-value-id");
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $(this).attr("disabled", true);
            $.ajax({
                url: url,
                type: "post",
                dataType: "json",
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function (data) {
                    displayMessage(data, button, iconElement);
                },
                error: function (err) {
                    displayMessage(err, button);
                }
            });
        });
    },

    isPublicCheckboxTicked: function () {
        $(document).ready(function() {
            // Get the isPublicList checkbox
            var $isPublicListCheckbox = $("#isPublicList");
            // Check if the isPublicList checkbox exists
            if ($isPublicListCheckbox.length) {
                // Check the initial state of the isPublicList checkbox and set individual checkboxes accordingly
                setIndividualCheckboxes($isPublicListCheckbox.prop("checked"));
                // Add an event listener to the isPublicList checkbox
                $isPublicListCheckbox.on("change", function() {
                    // Get the checked state of the isPublicList checkbox
                    var isChecked = $isPublicListCheckbox.prop("checked");
                    // Set the state of individual checkboxes
                    setIndividualCheckboxes(isChecked);
                });
            }
        });
    },
};
