/* eslint-env jquery */

"use strict";

var cart = require("../cart/cart");
var updateMiniCart = true;

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($("#addGiftWrappingModal").length !== 0) {
        $("#addGiftWrappingModal").remove();
    }
    var htmlString = "<!-- Modal -->"
        + "<div class=\"modal fade\" id=\"addGiftWrappingModal\" tabindex=\"-1\" role=\"dialog\">"
        + "<span class=\"enter-message sr-only\" ></span>"
        + "<div class=\"modal-dialog gift-wrapping-dialog\">"
        + "<!-- Modal content-->"
        + "<div class=\"modal-content\">"
        + "<div class=\"modal-header\">"
        + "    <h4 class=\"modal-title\"></h4>"
        + "    <button type=\"button\" class=\"close pull-right\" data-dismiss=\"modal\">"
        + "        <i class=\"icon-close icon-small\" aria-hidden=\"true\">&times;</i>"
        + "    </button>"
        + "</div>"
        + "<div class=\"modal-body\"></div>"
        + "<div class=\"modal-footer\"></div>"
        + "</div>"
        + "</div>"
        + "</div>";
    $("body").append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $("<div>").append($.parseHTML(html));

    var header = $html.find(".modal-title").text();
    var body = $html.find(".gift-wrapping-form");
    var footer = $html.find(".modal-footer").children();

    return { header: header, body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $(".modal-body").spinner().start();
    $.ajax({
        url: editProductUrl,
        method: "GET",
        dataType: "json",
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $("#addGiftWrappingModal .modal-body").empty();
            $("#addGiftWrappingModal .modal-header .modal-title").text(parsedHtml.header);
            $("#addGiftWrappingModal .modal-body").html(parsedHtml.body);
            $("#addGiftWrappingModal .modal-footer").html(parsedHtml.footer);
            $("#addGiftWrappingModal .modal-header .close .sr-only").text(data.closeButtonText);
            $("#addGiftWrappingModal .enter-message").text(data.enterDialogMessage);
            $("#addGiftWrappingModal").modal("show");
            $("body").trigger("addGiftWrappingModal:ready");
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}
function giftWrapping() {
    $("body").on("click", ".cart-page .add-gift-wrapping, .cart-page .edit-gift-wrapping", function (e) {
        e.preventDefault();

        var addingGiftWrappingUrl = $(this).attr("href");
        getModalHtmlElement();
        fillModalElement(addingGiftWrappingUrl);
    });

    $("body").on("click", "#addGiftWrappingModal .gift-wrapping-submit", function (e) {
        e.preventDefault();

        var form = $("#addGiftWrappingModal .gift-wrapping");
        var url = form.attr("action");
        var data = form.serialize();
        $.spinner().start();
        $.ajax({
            url: url,
            method: "POST",
            dataType: "json",
            data: data,
            success: function (res) {
                $.spinner().stop();

                if (res.success) {
                    $(".cart-page .add-gift-wrapping").removeClass("d-none");
                    $(".cart-page .gift-wrapping-message").addClass("d-none");

                    var dataArr = form.serializeArray();

                    if (dataArr.length) {
                        dataArr.forEach(function (item) {
                            var names = item.name.split("enableGiftWrapping-");
                            if (names.length > 1) {
                                var uuid = names[1];

                                $(".uuid-" + uuid + " .gift-wrapping-message").removeClass("d-none");
                                $(".uuid-" + uuid + " .add-gift-wrapping").addClass("d-none");
                            }
                        });
                    }
                    $("#addGiftWrappingModal").modal("hide");
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $.spinner().stop();
            }
        });
    });
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    var status = data.success ? "alert-success" : "alert-danger";

    if ($(".add-to-wishlist-messages").length === 0) {
        $("body").append("<div class=\"add-to-wishlist-messages \"></div>");
    }
    $(".add-to-wishlist-messages")
        .append("<div class=\"add-to-wishlist-alert text-center " + status + "\">" + data.msg + "</div>");

    setTimeout(function () {
        $(".add-to-wishlist-messages").remove();
    }, 1500);
    var $targetElement = $("a[data-pid=\"" + data.pid + "\"]").closest(".product-info").find(".remove-product");
    var itemToMove = {
        actionUrl: $targetElement.data("action"),
        productID: $targetElement.data("pid"),
        productName: $targetElement.data("name"),
        uuid: $targetElement.data("uuid")
    };
    $("body").trigger("afterRemoveFromCart", itemToMove);
    setTimeout(function () {
        $(".cart.cart-page #removeProductModal").modal();
    }, 2000);
}
/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function moveToWishlist() {
    $("body").on("click", ".product-move .move", function (e) {
        e.preventDefault();
        var url = $(this).attr("href");
        var pid = $(this).data("pid");
        var optionId = $(this).closest(".product-info").find(".lineItem-options-values").data("option-id");
        var optionVal = $(this).closest(".product-info").find(".lineItem-options-values").data("value-id");
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal
            },
            success: function (data) {
                displayMessageAndRemoveFromCart(data);
            },
            error: function (err) {
                displayMessageAndRemoveFromCart(err);
            }
        });
    });
}

module.exports = function () {
    cart();
    moveToWishlist();
    giftWrapping();

    $(".minicart").on("count:update", function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $(".minicart .minicart-quantity").text(count.quantityTotal);
            $(".minicart .minicart-link").attr({
                "aria-label": count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $(".minicart").on("mouseenter focusin touchstart", function () {
        if ($(".search:visible").length === 0) {
            return;
        }
        var url = $(".minicart").data("action-url");
        var count = parseInt($(".minicart .minicart-quantity").text(), 10);

        if (count !== 0 && $(".minicart .popover.show").length === 0) {
            if (!updateMiniCart) {
                $(".minicart .popover").addClass("show");
                return;
            }

            $(".minicart .popover").addClass("show");
            $(".minicart .popover").spinner().start();
            $.get(url, function (data) {
                $(".minicart .popover").empty();
                $(".minicart .popover").append(data);
                updateMiniCart = false;
                $.spinner().stop();
            });
        }
    });
    $("body").on("touchstart click", function (e) {
        if ($(".minicart").has(e.target).length <= 0) {
            $(".minicart .popover").removeClass("show");
        }
    });
    $(".minicart").on("mouseleave focusout", function (event) {
        if ((event.type === "focusout" && $(".minicart").has(event.target).length > 0)
            || (event.type === "mouseleave" && $(event.target).is(".minicart .quantity"))
            || $("body").hasClass("modal-open")) {
            event.stopPropagation();
            return;
        }
        $(".minicart .popover").removeClass("show");
    });
    $("body").on("change", ".minicart .quantity", function () {
        if ($(this).parents(".bonus-product-line-item").length && $(".cart-page").length) {
            location.reload();
        }
    });
    $("body").on("product:afterAddToCart", function () {
        updateMiniCart = true;
    });
    $("body").on("cart:update", function () {
        updateMiniCart = true;
    });
};
