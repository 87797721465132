/* eslint-disable linebreak-style */
/* eslint-disable quotes */
/* eslint-disable no-undef */
"use strict";

$(document).ready(function () {
    const ageCheckConsent = getCookie('ageCheckConsent');
    if (!ageCheckConsent) {
        $("#ageConsentModal").modal("show");
    }
});

$(document).on('click', '.btnYes', function () {
    setCookie('ageCheckConsent', 'true');
    $("#ageConsentModal").modal("hide");
});

$(document).on('click', '.btnNo', function () {
    var redirectUrl = $(this).data("redirect");
    window.location.href = redirectUrl;
});

$(document).on('click', '.age-consent-popup .close', function () {
    var redirectUrl = $(this).data("redirect");
    window.location.href = redirectUrl;
});

function getCookie(name) {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return null;
}

function setCookie(name, value) {
    const cookie = name + "=" + value + "; path=/";
    document.cookie = cookie;
}
